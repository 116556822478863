.kitchenOrders {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orders__backButton {
    background-color: #4CAF50;
    color: white;
}

.orders {
    width: 50%;
    justify-content: center;
}

.orders__individualOrders {
    margin: 60px 0;
}

.orders__total {
    text-align: center;
}

.order__status {
    text-align: center;
}

.order__status h3 {
    background-color: #FCF8E3;
    margin: 10px 0;
    padding: 10px 0;
}

.order__status select {
    display: flex;
    width: 100%;
    margin: 10px 0;
    height: 30px;
}

.order__status button {
    padding: 10px;
}

.order__statusInfo {
    list-style: none;
}

.order__statusInfo li {
    margin: 10px 0;
}

.orders__individualOrders h3 {
    margin: 10px 0;
}

.orders__paginationStack {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 769px) {
    .orders {
        width: 90%;
    }
}