.menu {
  min-width: 100vw;
}

.menu__topSection {
  height: 75vh;
  background-color: black;
}

.menu__topText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
}

.menu__appStoreLogo {
  height: 200px;
}