.createProducts {
  display: flex;
  width: 100%;
}

.createProducts__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
}

.createProducts__form h4 {
  text-align: center;
  margin: 10px 0;
}

.createProducts__photoData {
  width: 75%;
  background-color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 5px;
  padding: 10px 10px;
}

.createProducts__input {
  margin: 15px 0;
}

.createProducts__description
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  height: 120px;
}

.createProducts__inputDropdown {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.createProducts__inputDropdown select {
  height: 40px;
  margin: 5px 0;
}

.createProducts__button {
  margin: 20px auto;
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 15px 40px;
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createProducts__button:hover {
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }