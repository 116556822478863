.checkout {
  width: 100%;
}

.checkout__form {
  list-style-type: none;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.checkout__stripeForm {
  height: 40px;
}

.checkout__form li {
  border: 1px solid lightgray;
  padding: 10px;
}

.checkout__inputForm {
  border: 1px solid lightgray;
  margin: 20px 0;
}

.checkout__signin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout__signin button {
  background-color: rgb(67, 67, 243);
  color: white;
  margin: 20px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 2px;
}

.checkout__footerBottom {
  position: relative !important;
  bottom: unset;
  width: 100%;
  margin-top: calc(100vh - 539px);
}

@media only screen and (max-width: 769px) {
  .checkout__form {
    width: 75%;
  }

  .checkout__footerBottom {
    position: relative !important;
    bottom: unset;
    margin-top: calc(100vh - 580px);
  }

  .css-i4bv87-MuiSvgIcon-root {
    height: 0.5em !important;
    width: 0.75em !important;
    margin-top: 5px;
  }
}
