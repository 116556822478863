.home {
  max-width: 100vw;
  overflow: hidden;
}

.home__mainImage {
  min-height: 90vh;
  min-width: 100vw;
  background-image: url("../assets/img/limeCilantro.png");
  background-size: 100%;
}

.home__imageText {
  position: absolute;
  width: 35%;
  top: 220px;
  left: 75px;
}

.home__imageText h1 {
  color: black;
  font-size: 130px;
  line-height: 130px;
}

.home__imageTextRed {
  color: #ff0000;
}

.home__imageText p {
  color: black;
  margin-top: 50px;
  font-size: 20px;
}

.home__mainText {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.home__mainText p {
  margin: 75px;
  width: 65%;
}

.home__ribbonPic {
  max-width: 50%;
  max-height: 50%;
  object-fit: contain;
}

@media only screen and (max-width: 1440px) {
  .home__imageText {
    top: 200px;
    left: 70px;
  }

  .home__imageText h1 {
    font-size: 100px;
    line-height: 100px;
  }

  .home__imageText p {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .home__imageText {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 60%;
    text-align: center;
    top: 100px;
  }

  .home__imageText h1 {
    font-size: 70px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 769px) {
  .home__imageText h1 {
    font-size: 35px;
    line-height: 50px;
    margin-top: 40px;
  }

  .home__mainImage {
    min-height: 80vh;
    background-image: url("../assets/img/limeCilantroMobile.png");
    background-size: 100% auto;
  }

  .home__ribbonPic {
    max-width: 100%;
  }
}
