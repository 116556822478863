.jobs {
  min-height: 100vh;
  margin-left: 20px;
}

.jobs > h1 {
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.jobs__positions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.jobs__positionApply {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  width: 45%;
}

.jobs__positionApply > h3 {
  margin-right: 20px;
}

.jobs__buttonLinks {
  padding: 15px 35px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #29b656;
}

.jobs__buttonLinks:hover {
  background-color: #126B37;
  color: white;
  transform: 0.4s ease;
}

.jobs__dropdown {
  margin-left: 20px;
  width: 20%;
}

.application {
  margin: 20px;
}

.application h3,
.application h4 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.application li {
  list-style-type: none;
}

.application__button {
  display: flex;
  justify-content: center;
}

.application__button button {
  width: 100px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
  background-color: #29b656;
}

.application__button button:hover {
  background-color: #126B37;
  color: white;
  transform: 0.4s ease;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .jobs {
    margin-left: 0;
  }

  .jobs__positionApply {
    width: 80%;
  }
}