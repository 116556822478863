.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 20px !important;
}

.css-m5x0z-MuiTypography-root a img {
  max-height: 100px;
}

.header {
  background-color: #212121 !important;
}

.header__logoMobile {
  display: none;
}

.header__logo img {
  max-height: 100px;
  object-fit: contain;
}

.header__profileLinks {
  display: flex;
  flex-direction: column;
  color: black;
}

.header__blackText {
  color: black;
}

.header__profileLinks a {
  color: black;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

@media screen and (max-width: 900px) {
  .css-1lfs2tq-MuiTypography-root {
    height: 50px;
  }

  .css-m5x0z-MuiTypography-root a img {
    max-height: 40px;
  }

  .header__logoMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .header__logoMobile img {
    max-height: 67px;
    text-align: left;
  }

  .header__logo img {
    max-height: 20px;
    object-fit: contain;
    display: none;
  }
}
