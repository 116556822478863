.css-1k9ek97 {
  display: flex;
  justify-content: space-evenly;
  background-color: lightgray !important;
  border: 1px solid;
}

.css-1ujnqem-MuiTabs-root {
  min-height: 70px !important;
  align-items: center;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  margin: 10px !important;
}

.tabNames {
  width: 100vw !important;
}
