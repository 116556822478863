.menu__categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menu__categories h3, .menu__categories h5 {
    text-align: center;
    margin: 20px;
}

