.adminDashboard {
    display: flex;
    flex-direction: column;
}

.adminDashboard button {
    background-color: #00ACc1;
    color: white;
    margin: 20px 10px;
    padding: 10px;
    min-width: 145px;
}