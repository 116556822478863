.createIngredients {
  display: flex;
  width: 100%;
}

.createIngredients__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
}

.createIngredients__input {
  margin: 15px 0;
}

.createIngredients__description
  .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  height: 120px;
}

.createIngredients__button {
  margin: 20px auto;
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 15px 40px;
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createIngredients__button:hover {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
