.createCategory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.createCategory__formGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  margin: 2rem 0;
}

.createCategory__input {
  width: 50%;
}

.createCategory__form {
  width: 100%;
}

.createCategory__button {
  margin: 20px auto;
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 10px;
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createCategory__button:hover {
  background-color: #007bff;
  color: white;
}

.createCategory a {
  color: #007bff;
  text-decoration: none;
}

.createCategory a:hover {
  text-decoration: underline;
}

.createCategory__backButton a:visited {
  color: #007bff;
  text-decoration: none;
}
