.cart {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.cart__items > h1 {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.cart__items {
  margin-top: 100px;
}

.cart__item {
  list-style-type: none;
  border: 1px solid lightgray;
  margin: 20px 15%;
  padding: 50px;
}

.cart__quantity {
  display: flex;
  align-items: center;
}

.cart__button .MuiSvgIcon-root {
  color: black;
}

.individual__order h1 {
  text-align: center;
}

.individualOrder__reorder {
  margin-top: 10px;
  background-color: #45d21e;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.individualOrder__reorder:hover {
  cursor: pointer;
}

.individualOrder__amountInfo {
  text-align: left;
  margin: 0 320px;
  padding: 0 50px;
  list-style: none;
}

.cart__remove {
  background-color: red;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  color: white;
}

.cart__remove:hover {
  cursor: pointer;
  background-color: #e90c0c;
  color: black;
}

.alert__success svg {
  color: black;
}

@media only screen and (max-width: 769px) {
  .cart__item {
    margin: 10px;
  }

  .individualOrder__amountInfo {
    margin: 0;
    text-align: center;
  }
}
