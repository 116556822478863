.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile__eachOrder {
  border: 1px solid black;
  border-radius: 5px;
  margin: 20px;
  padding: 20px 40px;
  min-width: 750px;
}

.profile__viewOrderButton {
  display: flex;
  justify-content: center;
}

.profile__viewOrderButton a button {
  margin-top: 10px;
  background-color: #45d21e;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.profile__viewOrderButton a button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .profile {
    width: 100%;
    max-width: 750px;
  }
  .profile__eachOrder {
    max-width: 100%;
    min-width: 75%;
  }
}
