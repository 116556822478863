.footer {
  text-align: center;
  color: white;
  background-color: #212121;
}

.footer__center {
  display: flex;
  justify-content: space-evenly;
  padding: 35px 0 0 0;
  color: white;
  background-color: #212121;
}

.footer__left {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.footer__left a {
  text-decoration: underline;
  color: white;
}

.MuiSvgIcon-root {
  color: white;
}

.MuiSvgIcon-root:hover {
  color: blue;
}

.footer__left a:hover {
  color: blue;
}

.footer__middle {
  text-align: center;
}

.css-i4bv87-MuiSvgIcon-root,
.css-i4bv87-MuiSvgIcon-root {
  font-size: 40px !important;
}

.footer__right {
  text-align: center;
}

.footer__right h1 {
  margin-right: 20px;
}

.footer__hours {
  display: flex;
  text-align: left;
}

.footer__hoursRight {
  margin-left: 10px;
}

.footer__copyright {
  padding: 20px;
}

.footer__socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__appStore {
  height: 40px;
  object-fit: contain;
}

@media screen and (max-width: 540px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer__center {
    display: flex;
    flex-direction: column;
  }

  .footer__right {
    justify-content: center;
  }

  .footer__hours {
    justify-content: center;
  }
}
