.updateCategory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.updateCategory__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  margin: 2rem 0;
}

.updateCategory__inputDropdown {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.updateCategory__inputDropdown select {
  height: 40px;
  margin: 5px 0;
}

.updateCategory__input {
  width: 50%;
}

.updateCategory__button {
  margin: 20px auto;
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 15px 40px;
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateCategory__button:hover {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.updateCategory__backButton a:hover {
  text-decoration: underline;
}

.updateCategory__backButton a, .updateCategory__backButton a:visited {
  color: #007bff;
  text-decoration: none;
}
