/* .css-hlj6pa-MuiDialogActions-root {
  display: flex !important;
  justify-content: center !important;
} */

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card__outer {
  flex: 0 0 33%;
  margin: 20px;
  justify-content: space-evenly;
  min-height: 100px;
  max-width: 400px;
  border: 1px solid black;
  border-radius: 5px;
}

.card__inner {
  /* margin: 20px; */
  min-height: 225px;
  position: relative;
}

.card__belowImage {
  padding: 0 20px 20px 20px;
}

.card__imgContainer {
  width: 100%;
}

.card__img {
  width: 100%;
  height: 212px;
  object-fit: contain;
  border-bottom: 1px solid black;
}

.card__name {
  margin: 10px 0;
}

.card__description {
  margin: 10px 0;
}

.card__price {
  margin: 10px 0;
}

.card__addToCart {
  display: flex;
  justify-content: center;
}

.card__addToCart > button {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 30px;
  cursor: pointer;
  background-color: #29b656;
  color: black;
}

.card__addToCart > button:hover {
  background-color: #126B37;
  color: white;
  transform: 0.4s ease;
}

.cardDialog__addToCart {
  display: flex;
  justify-content: center !important;
}

@media only screen and (max-width: 769px) {
  .card__outer {
    flex: 100%;
  }
}
