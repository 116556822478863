.signupForm {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signupForm__avatar {
    margin: 10px;
    background-color: Red;
}

.signupForm__form {
    width: 100%;
    margin-top: 10px;
}

.signupForm .css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
    margin: 20px 0;
}

.signupForm .css-i4bv87-MuiSvgIcon-root {
    color: black;
}